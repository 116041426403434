import ClientOnly from '@/components/ClientOnly';
import { Icon, IconVariant } from '@/components/Icon';
import OffLine from '@/components/OffLine';
import OfflinePlaceholder from '@/components/OfflinePlaceholder';
import OnLine from '@/components/OnLine';
import YoutubePlaceholder from '@/components/YoutubePlaceholder';
import classnames from 'classnames';
import React from 'react';
import TextToggle from './TextToggle';

export interface VideoProps {
  url: string;
  title?: string;
  description?: string;
  volltextVersion?: string;
  boxed?: boolean;
  is360?: boolean;
  variant: string;
  thumbnailVersion?: string;
}

interface VideoState {
  started: boolean;
  loaded: boolean;
  videoRef: any;
}

export class Video extends React.Component<VideoProps, VideoState> {
  static defaultProps: Partial<VideoProps> = {
    title: '',
    description: '',
    volltextVersion: undefined,
    boxed: false,
    is360: false,
    variant: '',
    thumbnailVersion: 'sddefault',
  };

  handleOnClick = (event): void => {
    const proceed =
      !('CustomEvent' in window) ||
      window.dispatchEvent(
        new CustomEvent('lotto:willPlayVideo', {
          cancelable: true,
          detail: {
            url: this.props.url,
            event,
          },
        })
      );
    if (!proceed) {
      return;
    }

    this.setState(() => {
      return {
        started: true,
      };
    });
  };

  handleOnLoad = (): void => {
    this.setState(() => {
      return {
        loaded: true,
      };
    });
  };

  constructor(props, state) {
    super(props, state);

    this.state = {
      started: false,
      loaded: false,
      videoRef: null,
    };
  }

  private get hasDescription(): boolean {
    const { title, description } = this.props;
    return (
      (title !== undefined && title !== '') ||
      (description !== undefined && description !== '')
    );
  }

  private get videoId(): string {
    const url = this.props.url;
    const parts = url.match(/([\/=]+)([a-zA-Z0-9_-]*)$/);
    if (!parts || parts.length !== 3) {
      return '';
    }
    return parts[2];
  }

  private get videoSrc(): string {
    const params = [
      'modestbranding=1',
      'playsinline=1',
      'autohide=1',
      'autoplay=1',
      'showinfo=0',
      'color=white',
      'controls=2',
      'rel=0',
    ];
    return `https://www.youtube-nocookie.com/embed/${
      this.videoId
    }?${params.join('&')}`;
  }

  private get thumbnailVersion(): string {
    return (
      this.props.thumbnailVersion ||
      (Video.defaultProps.thumbnailVersion as string)
    );
  }

  render(): JSX.Element {
    return (
      <figure
        className={classnames('Video', {
          'Video--started': this.state.started,
          'Video--loaded': this.state.loaded,
          'Video--boxed': this.props.boxed,
        })}
        data-info={this.props.url}
      >
        <OffLine>
          <div className="Video__container Video__container--offline">
            <OfflinePlaceholder className="Video__thumbnail Video__thumbnail--offline" />
          </div>
        </OffLine>

        <OnLine>
          <div className="Video__container">
            <ClientOnly>
              <YoutubePlaceholder
                videoid={this.videoId}
                is360={this.props.is360}
                variant={this.props.variant}
                thumbnailversion={this.thumbnailVersion}
                alt={this.props.title || ''}
                onClick={this.handleOnClick}
              />
              {this.state.started && (
                // FIXME
                // eslint-disable-next-line jsx-a11y/iframe-has-title
                <iframe
                  onLoad={this.handleOnLoad}
                  src={this.videoSrc}
                  key={this.videoSrc}
                  className="Video__frame"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="no"
                  allowFullScreen={true}
                  allow="autoplay"
                />
              )}
            </ClientOnly>
            <button
              className="Video__play"
              title="Video abspielen"
              onClick={this.handleOnClick}
            >
              <Icon variant={IconVariant.Play} />
            </button>
          </div>
        </OnLine>
        {(this.hasDescription || this.props.volltextVersion) && (
          <>
            <figcaption className="Video__description">
              {this.props.title && <span>{this.props.title}</span>}
              {this.props.description && <p>{this.props.description}</p>}
              {this.props.volltextVersion && (
                <TextToggle triggerText="Volltext-Version">
                  <div
                    className="full-width-content"
                    dangerouslySetInnerHTML={{
                      __html: this.props.volltextVersion,
                    }}
                  />
                </TextToggle>
              )}
            </figcaption>
          </>
        )}
      </figure>
    );
  }
}

export default Video;
