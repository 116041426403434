import { useState } from 'react';

export default function TextToggle({ triggerText, children }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="TextToggle">
      <button
        className="TextToggle__button"
        onClick={() => setIsVisible(!isVisible)}
      >
        {triggerText}
      </button>
      {isVisible && <div className="TextToggle__content">{children}</div>}
    </div>
  );
}
